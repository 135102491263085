<template>
  <div
    class="message d-flex flex-column justify-content-center align-items-center position-relative"
  >
    <div class="d-flex flex-column align-items-center justify-content-center">
      <h2 class="welcome">
        Welcome to
        <img class="logo" src="../assets/images/landing_logo.png" alt="" />
      </h2>
      <h1 class="para">New <span>Network Community</span></h1>
      <h1 class="para">and <span>Smart E-learning</span></h1>
      <!-- <h1 class="come">Coming Soon</h1> -->
    </div>
    <img
      class="spongaGIF"
      src="../assets/images/Sponga_loading.gif"
      alt="spongaGIF"
    />
  </div>
</template>

<script>
export default {
  name: "Landing",
};
</script>

<style>
body {
  background-color: #8888ff;
  color: #ffffff;
  background-image: url("../assets/images/bg.png");
  background-size: 100%;
}

span {
  color: #fcef79;
}

.logo {
  padding-left: 5px;
  width: 100%;
  max-width: 60px;
}

.message {
  height: 100vh;
}

.welcome {
  font-size: 15px !important;
}

.para {
  font-size: 20px !important;
  font-weight: 500;
}

.come {
  margin: 5px 0 50px 0;
  font-size: 15px !important;
  padding: 0 20px;
  border: 3px solid #ffffff;
  border-radius: 150px;
}

.spongaGIF {
  width: 100%;
  max-width: min(600px, 50vw);
  max-height: 22vh;
  position: absolute;
  bottom: 0;
  object-fit: contain;
}

@media only screen and (min-width: 540px) {
  .logo {
    padding-left: 5px;
    width: 100%;
    max-width: 70px;
  }

  .message {
    height: 100vh;
  }

  .welcome {
    font-size: 23px !important;
  }

  .para {
    font-size: 28px !important;
    font-weight: 500;
  }

  .come {
    margin: 8px 0 50px 0;
    font-size: 23px !important;
    padding: 0 20px;
    border: 3px solid #ffffff;
    border-radius: 150px;
  }
}

@media only screen and (min-width: 768px) {
  .logo {
    padding-left: 5px;
    width: 100%;
    max-width: 80px;
  }

  .message {
    height: 100vh;
  }

  .welcome {
    font-size: 25px !important;
  }

  .para {
    font-size: 35px !important;
    font-weight: 500;
  }

  .come {
    margin: 10px 0 50px 0;
    font-size: 25px !important;
    padding: 0 20px;
    border: 3px solid #ffffff;
    border-radius: 150px;
  }
}

@media only screen and (min-width: 920px) {
  .logo {
    padding-left: 5px;
    width: 100%;
    max-width: 100px;
  }

  .message {
    height: 100vh;
  }

  .welcome {
    font-size: 40px !important;
  }

  .para {
    font-size: 50px !important;
    font-weight: 500;
  }

  .come {
    margin: 15px 0 50px 0;
    font-size: 40px !important;
    padding: 0 20px;
    border: 3px solid #ffffff;
    border-radius: 150px;
  }
}

@media only screen and (min-width: 1300px) {
  .logo {
    padding-left: 5px;
    width: 100%;
    max-width: 130px;
  }

  .message {
    height: 100vh;
  }

  .welcome {
    font-size: 50px !important;
  }

  .para {
    font-size: 60px !important;
    font-weight: 500;
  }

  .come {
    margin: 20px 0 50px 0;
    font-size: 45px !important;
    padding: 0 20px;
    border: 3px solid #ffffff;
    border-radius: 150px;
  }
}
</style>
